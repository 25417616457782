const headeerSticky = (sHeader) => {
    const header = document.querySelector(sHeader);
    // const headeroffsetTop = header.offsetTop;
    const headrHeight = header.offsetHeight;
    const sticky = () => {
        console.log('scroll');
        if (window.scrollY > headrHeight) {
            header.classList.add('sticky');
        }
        else {
            header.classList.remove('sticky');
        }
    };
    window.addEventListener('scroll', sticky);
};
export default headeerSticky;
