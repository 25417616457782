const matrikaReachGoals = (counterId, goals) => {
    // matrikaReachGoals('46147254', [
    // 	'formClick',
    // 	'emailClick',
    // 	'phoneClick'
    // ]);
    goals && goals.forEach(goal => {
        document.querySelectorAll(goal.target).forEach(elt => {
            elt.addEventListener(goal.goalEvent, () => {
                window.ym(counterId, 'reachGoal', goal.goalId);
                console.log(goal.goalEvent, goal.goalId, goal.target);
            });
        });
    });
    // const objectActionBtn = $('#objectActionBtn');
    // const objectActionBtnReachGoal = () => {
    //     window.ym(489158,'reachGoal','objectActionBtn');
    //     window.gtag('event', 'Click', {
    //         'event_category' : 'Product_action_btn',
    //         'event_label' : 'Получить консультацию'
    //     })
    //     return true;
    // }
    // objectActionBtn && objectActionBtn.on('click', objectActionBtnReachGoal);
    // const orderFormSubmitBtn = $('#orderFormSubmitBtn');
    // const orderFormSubmitBtnReachGoal = () => {
    //     window.ym(489158,'reachGoal','orderFormSubmitBtn');
    //     window.gtag('event', 'Click', {
    //         'event_category' : 'Webform_send',
    //         'event_label' : 'Отправить'
    //     })
    //     return true;
    // }
    // orderFormSubmitBtn && orderFormSubmitBtn.on('click', orderFormSubmitBtnReachGoal);
};
export default matrikaReachGoals;
// gtag('event', 'aaa', {
//     'event_category' : 'bbb',
//     'event_label' : 'ccc'
//   });
