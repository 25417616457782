import StepsForm from './Stepsform.ts';
import {initFormValidate} from './formValidate';
import {FormValidate} from './formValidate';
import {initAntiSpam} from './antiSpam';
import addSourcePageToForm from './addSourcePageToForm.ts';
import submiter from './submiter.ts';

export {
    StepsForm,
    initFormValidate,
    FormValidate,
    initAntiSpam,
    addSourcePageToForm,
    submiter
}