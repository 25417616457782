import { $$ } from './utils';
import { Fancybox } from '@fancyapps/ui';
import "@fancyapps/ui/dist/fancybox/fancybox.css";
const fancy = (selectors) => {
    Fancybox.bind('[data-fancybox="gallery"]', {});
    selectors.forEach(item => {
        $$(`.${item.trigger}`).forEach((trigger) => {
            trigger.on('click', () => {
                Fancybox.show([{
                        src: `#${item.form}`
                    }]);
            });
        });
    });
};
// Fancybox.show([{ src: "#dialog-content", type: "inline" }]);
export default fancy;
