export default function submiter(form) {
    const ajaxSend = async (url, formData) => {
        const fetchResp = await fetch(url, {
            method: 'POST',
            body: formData
        });
        if (!fetchResp.ok) {
            throw new Error(`Ошибка по адресу ${url}, статус ошибки ${fetchResp.status}`);
        }
        return await fetchResp.text();
    };
    const formData = new FormData(form);
    ajaxSend('/webforms/secureSend/', formData)
        .then(response => {
        processing(response);
        form.reset();
        window.ym(46147254, 'reachGoal', 'formSubmited');
    })
        .catch(err => console.error(err));
    const processing = (pageSourceHtml) => {
        form.parentElement.innerHTML = `
            <div class="col-24 column">
                <h1>Сообщение отправлено</h1>
                <p>В ближайшее время менеджер ответит на ваше сообщение</p>
            </div>
        `;
        // pageSourceHtml.indexOf('<div class="{$col-medium} justify-center column formPostedMessege">')
    };
}
;
