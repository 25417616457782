import "./polyfills";
import cardClick from './cardClick';
// import { formValidate } from './formValidate';
import {mobileMenu} from './menu';
import tabs from './tabs';
import {initAntiSpam, initFormValidate, addSourcePageToForm} from './forms';
import "@fancyapps/ui/dist/fancybox/fancybox.css";
import matrikaReachGoals from './matrikaReachGoals.ts';
import fancy from './fancy.ts';
import upBtn from "./upBtn.ts";
import headeerSticky from './headeerSticky.ts';

window.addEventListener('DOMContentLoaded', function(){

	headeerSticky('.header-mobile');
	// Переход на страницу по клику на карточку в любом месте кроме корзины
	cardClick('[data-component="cards"]');

	//Мобильное меню
	mobileMenu('[data-component="mobile_menu_trigger"]', '[data-component="mobile_menu"]');
	// -------------------

	tabs('tabs__nav', 'tabs__body');

	initFormValidate('.form', ['form_search']);
	initAntiSpam('.form', ['form_search']);
	addSourcePageToForm('.sourcePage');

	matrikaReachGoals('46147254', [
		{target:'.phoneClick', goalId: 'phoneClick', goalEvent: 'click'},
		{target:'.emailClick', goalId: 'emailClick', goalEvent: 'click'},
		{target:'.submit', goalId: 'submitBtnClick', goalEvent: 'click'},
		{target:'.downloadFile', goalId: 'downloadFile', goalEvent: 'click'},
		{target:'.phone-link', goalId: 'copy_phone', goalEvent: 'copy'},
		{target:'.emailClick', goalId: 'copy_email', goalEvent: 'copy'},
	]);

	// Fancy
	const selectors = [
		{trigger: 'objectActionBtn', form: 'productConsult'},
		{trigger: 'callbackBtn', form: 'callbackForm'},
	]
	fancy(selectors)

	upBtn();

});