const upBtn = () => {
    const btn = document.querySelector('.upbtn');
    if (!btn) {
        return;
    }
    const show = () => {
        btn.classList.remove('hide');
    };
    const hide = () => {
        btn.classList.add('hide');
    };
    window.addEventListener('scroll', () => {
        const scrollY = window.scrollY || document.documentElement.scrollTop;
        scrollY > 400 ? show() : hide();
    });
    btn.addEventListener('click', () => {
        window.scroll({
            top: 0,
            left: 0,
            behavior: 'smooth'
        });
    });
};
export default upBtn;
